<div class="center">
    <p>Site: </p>
    <p>Slot:
    </p>

    <table>
        <tr>
            <th>Athlète</th>
            <th>Club</th>
        </tr>
    </table>
</div>