import { Component, OnInit } from '@angular/core';
import { SiteInterface } from '../interfaces/site.interface';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
