//General / Natif
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

//Routes
import { APP_ROUTES } from './app.routes';

//import { AppRoutingModule } from './app-routing.module';
//Component
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContentComponent } from './content/content.component';
import { SiteDetailComponent } from './site-detail/site-detail.component';
import { SlotDetailComponent } from './slot-detail/slot-detail.component';
import { SiteListComponent } from './site-list/site-list.component';
import { SlotFormComponent } from './slot-form/slot-form.component';
import { SlotAthleteComponent } from './slot-athlete/slot-athlete.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ContentComponent,
    SiteDetailComponent,
    SlotDetailComponent,
    SiteListComponent,
    SlotFormComponent,
    SlotAthleteComponent
  ],
  imports: [
    BrowserModule,
    //AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(APP_ROUTES)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
