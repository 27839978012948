import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slot-athlete',
  templateUrl: './slot-athlete.component.html',
  styleUrls: ['./slot-athlete.component.scss']
})
export class SlotAthleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
