import { Routes } from "@angular/router";
import { SiteListComponent } from "./site-list/site-list.component";
import { SlotAthleteComponent } from "./slot-athlete/slot-athlete.component";
import { SlotDetailComponent } from "./slot-detail/slot-detail.component";
import { SlotFormComponent } from "./slot-form/slot-form.component";


export const APP_ROUTES: Routes = [
    { path: "", component: SiteListComponent },
    { path: "registration/:slotId", component: SlotFormComponent },
    { path: ":location", component: SlotDetailComponent },
    { path: "slotAthlete/:slotId", component: SlotAthleteComponent }
];