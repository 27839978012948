<div class="center">
    <!--<p>Formulaire à remplir</p>
    <br /><br />
    Controle licence:<br />
    <input type="text" value="N° Licence" />
    <input type="date" value="10/10/1910" />
    <br />
    Email:<br />
    <input type="email" value="info@prolivesport.fr" />
    <br /><br />
    <button routerLink="/">S'ENREGISTRER</button>-->

    <div class="size2" style="margin-bottom: 1rem; color:#93C93D; text-shadow: black 0 0 0.5rem;">
        <!--<u>Créneau sélectionné:</u><br />-->
        {{slotDetail.siteLocation}}
        <br />
        {{slotDetail.slotName}}
    </div>

    <form [formGroup]="formRegistration" (ngSubmit)="onSubmitForm()" *ngIf='registrationStatus && !confirmationText'
        class="size2">
        <div>
            <div>
                <label>Date de naissance: <span class="red">*</span></label>
                <input formControlName="licenseBirthDate" type="date" (change)="onKeyupBirthDate()" />
            </div>
            <div>
                <label>N° licence: <span class="red">*</span></label>
                <input formControlName="licenseNumber" type="text" (keyup)="onKeyupLicenseNumber()" />
            </div>
            <div class="error">{{licenseCheckError}}</div>
        </div>
        <div *ngIf='licenseCheckStatus'>
            <input formControlName="licenseSex" type="hidden" />
            <div>
                <label>Nom: <span class="red">*</span></label>
                <input formControlName="licenseLastname" readonly type="text" />
            </div>
            <div>
                <label>Prénom: <span class="red">*</span></label>
                <input formControlName="licenseFirstname" readonly type="text" />
            </div>
            <div>
                <label>Email: <span class="red">*</span></label>
                <input formControlName="licenseEmail" type="text" />
            </div>
            <div>
                <label>N° mobile (ex 0123456789): <span class="red">*</span></label>
                <input formControlName="licensePhone" type="tel" (keyup)="onKeyupPhone()" />
            </div>
            <div>
                <label>Adresse: <span class="red">*</span></label>
                <input formControlName="licenseAddrAddress" type="text" />
            </div>
            <div>
                <label>Code postal: <span class="red">*</span></label>
                <input formControlName="licenseAddrZipCode" type="text" />
            </div>
            <div>
                <label>Ville: <span class="red">*</span></label>
                <input formControlName="licenseAddrCity" type="text" />
            </div>
            <div>
                <label>Club:</label>
                <input formControlName="licenseTeam" readonly type="text" />
            </div>
            <div class="divCenter orange" *ngIf="slotDetail.siteLocation==='STAB-SEANCE'">
                En cliquant sur le bouton "Valider";
                <br />
                Je confirme avoir effectué un baptême sur la piste du STAB
            </div>
        </div>

        <button type="submit" [disabled]="formRegistration.invalid">Validation de l'inscription</button>

    </form>

    <div class="error size2" *ngIf="!registrationStatus">{{registrationError}}</div>

    <div class="green center size2 bg-1" *ngIf="confirmationText">
        <p>Confirmation de votre inscription au créneau sélectionné. Un email de confirmation vient de vous être envoyé
            (vérifiez vos spams).</p>
        <p>Un QR code y figure et pourra vous être demandé.</p>
        <p>Sur place, en complément du QRcode, munissez-vous d'une pièce d'identité, de votre licence.
            Pensez à arriver 15/20min en amont de la séance sélectionnée.
        </p>
        <p>Résumé:</p>
        <ul>
            <li>Site: {{confirmationText.siteLocation}} ({{confirmationText.siteAddress}})</li>
            <li>Créneau: {{confirmationText.slotName}}</li>
            <li>Athlète: {{confirmationText.registrationFirstname}} {{confirmationText.registrationLastname}}
                ({{confirmationText.registrationLicense}} {{confirmationText.registrationTeam}})</li>
        </ul>
        <p class="center"><img src='http://www.prolivesport.fr/tpl/2013/pict/PLS_logo/9_smallbis.png' width="300" /></p>
    </div>


    <br />
    <button routerLink="/{{slotDetail.siteLocation}}">RETOUR</button>

</div>