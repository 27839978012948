import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { API_URL } from '../app.config';
import { SiteInterface } from '../interfaces/site.interface';

@Component({
  selector: 'app-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss']
})
export class SiteListComponent implements OnInit {

  public sites: SiteInterface[];
  public siteSelected: SiteInterface = { siteId: 0, siteLocation: "", sitePict: "", siteDep: "", siteAddress: "" };

  constructor(private HttpService: HttpClient) {
    this.sites = [];
  }

  ngOnInit(): void {
    this.HttpService.get<SiteInterface[]>(API_URL + "site/").subscribe((siteList: SiteInterface[]) => {
      console.log(siteList);
      this.sites = siteList;

    });
    //console.log(result);

  }



  public selectSite(site: SiteInterface) {
    this.siteSelected = site;
  }

}
