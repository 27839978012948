import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { API_URL } from '../app.config';
import { LicenseInterface } from '../interfaces/license.interface';
import { RegistrationInterface } from '../interfaces/registration.interface';
import { SlotInterface } from '../interfaces/slot.interface';

@Component({
  selector: 'app-slot-form',
  templateUrl: './slot-form.component.html',
  styleUrls: ['./slot-form.component.scss']
})

export class SlotFormComponent implements OnInit {

  public formRegistration: FormGroup;
  public athleteData: LicenseInterface = { licenseNumber: '', licenseSex: '', licenseLastname: '', licenseFirstname: '', licenseEmail: '', licensePhone: '' };
  public licenseCheckStatus: boolean = false;
  public licenseCheckError: string = '';
  public registrationStatus: boolean = true;
  public registrationError: string = '';
  //public confirmationStatus: boolean = false;
  public confirmationText: any = "";

  private slotId: any = "";
  public slotDetail = { siteLocation: "", slotName: "" };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token'
    })
  };

  httpOptionSendiblue = {
    headers: new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'api-key': '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'
    })
  }

  //Vérification de la licence
  validatorLicense(formControl: FormControl): Observable<ValidationErrors | null> {
    return new Observable((observer: Observer<any>) => {
      const licenseCheck1 = formControl.value.substr(0, 1).toUpperCase();
      const licenseCheck2 = formControl.value.substr(1, 5);

      //Check fist digit of FF TRI license
      const licence1Letter = ["A", "B", "C"];
      if (licence1Letter.indexOf(licenseCheck1) >= 0) {
        //check 2nd to 6th digits of FF TRI license
        const check2 = /^-?[0-9]+$/;
        if (check2.test(licenseCheck2)) {
          observer.next(null);
          observer.complete();
        } else {
          observer.next({ isOk: false });
          observer.complete();
        }
      } else {
        observer.next({ isOk: false });
        observer.complete();
      }
    });
  }

  //Vérification d'une bonne date de naissance
  validatorBirthDate(formControl: FormControl): Observable<ValidationErrors | null> {
    return new Observable((observer: Observer<any>) => {
      const year = formControl.value.substr(0, 4);
      const month = formControl.value.substr(5, 2);
      const day = formControl.value.substr(8, 2);
      const date = new Date();

      if (year > 1900 && year <= date.getFullYear()) {
        observer.next(null);
        observer.complete();
      } else {
        observer.next({ isOk: false });
        observer.complete();
      }
    });
  }

  //Vérification d'une bonne date de naissance
  validatorPhone(formControl: FormControl): Observable<ValidationErrors | null> {
    return new Observable((observer: Observer<any>) => {
      const phone = formControl.value.replace(" ", "").replace(".", "");
      console.log(phone);

      //  if (true) {
      //    observer.next(null);
      //   observer.complete();
      // } else {
      observer.next({ isOk: false });
      observer.complete();
      //}
    });
  }


  constructor(private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private HttpService: HttpClient) {
    this.formRegistration = this.formBuilder.group({
      licenseNumber: ['', [Validators.required, Validators.minLength(6)], this.validatorLicense],
      licenseBirthDate: ['', [Validators.required], this.validatorBirthDate],
      licenseSex: ['', Validators.required],
      licenseLastname: ['', Validators.required],
      licenseFirstname: ['', Validators.required],
      licenseEmail: ['', [Validators.required, Validators.email]],
      licensePhone: ['', [Validators.required, Validators.pattern("[0-9]{10}")]],
      licenseTeam: ['', Validators.required],
      licenseAddrAddress: ['', Validators.required],
      licenseAddrZipCode: ['', [Validators.required, Validators.pattern("[0-9]{5}")]],
      licenseAddrCity: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.slotId = paramMap.get("slotId");

      this.HttpService.get<SlotInterface[]>(API_URL + "slot2/" + this.slotId).subscribe((slotList: any) => {
        //this.slotList = slotList;
        console.log("Site choose for form: " + slotList[0].siteLocation);
        console.log("Slot choose: for form: " + slotList[0].slotName);

        this.slotDetail = { siteLocation: slotList[0].siteLocation, slotName: slotList[0].slotName };
      });
    });
  }

  onSubmitForm() {
    const formValue = this.formRegistration.value;


    const registrationData: RegistrationInterface = {
      registrationSlotId: this.slotId,
      registrationLicense: formValue.licenseNumber,
      registrationSex: formValue.licenseSex,
      registrationLastname: formValue.licenseLastname,
      registrationFirstname: formValue.licenseFirstname,
      registrationEmail: formValue.licenseEmail,
      registrationPhone: formValue.licensePhone,
      registrationTeam: formValue.licenseTeam,
      registrationAddrAddress: formValue.licenseAddrAddress,
      registrationAddrZipCode: formValue.licenseAddrZipCode,
      registrationAddrCity: formValue.licenseAddrCity
    }

    this.HttpService.post(API_URL + "registration/", registrationData).subscribe((result: any) => {
      //Check error
      if (result.success === false) {
        this.registrationStatus = false;

        console.log("Registration not saved");
        switch (result.message) {
          case "Slot not found or not available":
            this.registrationError = "Créneau non trouvé ou date/heure de clôture dépassée";
            break;
          case "Max records reached":
            this.registrationError = "Nom maxi d\'enregistrement dépassé";
            break;
          case "Duplicate":
            this.registrationError = "Vous êtes déjà inscrit sur ce créneau !";
            break;
          case "Cancel":
            this.registrationError = "Vous avez fait une annulation; il n'est plus possible de vous ré-inscrire sur ce créneau !";
            break;
        }
      } else {
        console.log(result);

        //create QRCode
        /*this.HttpService.get("https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://swimtrihdf.prolivesport.fr/check.php?ref=123").subscribe((qrcode: any) => {
          console.log(qrcode);
        });*/

        //Save data pour affichage
        this.confirmationText = result;

        //send email confirmation
        this.HttpService.post("https://api.sendinblue.com/v3/smtp/email", {
          sender: {
            name: "HDF TRI App by ProLiveSport.fr",
            email: "info@prolivesport.fr"
          },
          to: [
            {
              email: result.registrationEmail,
              name: result.registrationFirstname + " " + result.registrationLastname
            }/*,
            {
              email: "info@prolivesport.fr",
              name: "ProLiveSport"
            }*/
          ],
          cc: [
            {
              email: "info@prolivesport.fr",
              name: "ProLiveSport"
            }
          ],
          subject: "Confirmation créneau HDF Tri " + result.siteLocation,
          htmlContent: "<html><head></head><body>" +
            "<p>Bonjour " + result.registrationFirstname + " ,</p>" +
            "<p>Votre inscription pour le créneau " + result.slotName + " / " + result.siteLocation + "  mis en place par la ligue Hauts-de-France de Triathlon est bien pris en compte.</p>" +
            "<p>Munissez-vous de votre licence, pièce d'identité; une vérification sur place sera effectuée. Pensez à arriver 15 à 20min à l'accueil en amont du créneau sélectionné.</p>" +
            "<p><b>Casque personnel (obligatoire) ainsi que vos chaussures si câles Shimano</b>. Prêt du vélo pendant la séance. Pensez à mesurer la hauteur de votre selle sur votre vélo de course afin de faciliter les règlages sur place: distance entre axe pédalier et creux de la selle</p><br />" +
            "<p><u>Détail:</u></p>" +
            "<p>Athlète: <b>" + result.registrationFirstname + " " + result.registrationLastname + " | " + result.registrationLicense + " (" + result.registrationTeam + ")</b>" + "</p>" +
            "<p>Créneau: <b>" + result.slotName + "</b> sur le site: <b>" + result.siteLocation + " (" + result.siteAddress + ")</b></p>" +
            "<img src='https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://trihdf.prolivesport.fr/check.php?ref=" + result.registrationId + "' width:150 />" +
            "<p>Excellente séance d'entraînement.</p><br />" +
            //"<p><a href='https://trihdf.prolivesport.fr/API/delete.php?id=" + result.registrationId + "'>Annuler le créneau (choix définitif non modifiable)</a></p>" +
            "<p>Annulation du créneau: Contacter Nicolas Pierens de la ligue en <a href='mailto:nicolas.pierens@triathlonhdf.fr?subject=TriHDF PLS - Annulation creneau&body=Creneau " + result.slotName + " " + result.siteLocation + " pour l athlete:" + result.registrationFirstname + " " + result.registrationLastname + "'>cliquant ici pour annuler votre créneau</a></p>" +
            "" +
            "<p>Sportivement,<p>" +
            "<p>Service inscriptions / ProLiveSport.fr</p>" +
            "<p>info@prolivesport.fr</p>" +
            "<p><img src='http://www.prolivesport.fr/tpl/2013/pict/PLS_logo/9_smallbis.png'  width= '200' /></p>" +
            "</body></html >"
        }, this.httpOptionSendiblue).subscribe((result: any) => {
          console.log("Email confirmation by Senbiblue");
          //console.log(result);
        });
        console.log("Registration saved");
        //alert('Inscription bien enregistrée');
      }
    });
  }



  onKeyupLicenseNumber() {
    this.licenseCheck();
  }

  onKeyupBirthDate() {
    this.licenseCheck();
  }

  onKeyupPhone() {
    this.formRegistration.patchValue({ licensePhone: this.formRegistration.value.licensePhone.replace(".", "").replace(" ", "") });
  }

  private licenseCheck() {
    this.licenseCheckStatus = false;

    //Get forms values
    const birthDate = this.formRegistration.value.licenseBirthDate;
    const license = this.formRegistration.value.licenseNumber;

    //Check controls form
    if (this.formRegistration.controls.licenseNumber.status === "VALID" && this.formRegistration.controls.licenseBirthDate.status === "VALID") {
      //if (this.formRegistration.status === "VALID") {
      this.HttpService.get<LicenseInterface[]>(API_URL + "licenseCheck/" + this.formRegistration.value.licenseNumber + "/" + birthDate).subscribe((result: LicenseInterface[]) => {
        if (result.length > 0) {
          this.formRegistration.patchValue({
            licenseSex: result[0].licenseSex,
            licenseLastname: result[0].licenseLastname,
            licenseFirstname: result[0].licenseFirstname,
            licenseEmail: result[0].licenseEmail,
            licenseTeam: result[0].licenseTeam,
            licensePhone: result[0].licensePhone,
            licenseAddrAddress: result[0].licenseAddress1,
            licenseAddrZipCode: result[0].licenseZipCode,
            licenseAddrCity: result[0].licenseCity
          });

          this.licenseCheckError = "";
          this.licenseCheckStatus = true;

          console.log("Vérif licence OK");
          console.log(this.formRegistration);
          console.log(this.registrationStatus);
          console.log(this.confirmationText);
          console.log(this.licenseCheckStatus);

          //alert("Welcome " + result[0].licenseLastname + " " + result[0].licenseFirstname + " | Full:" + result[0].licenseNumber)
        } else {
          console.log("Vérif licence NOK");
          this.licenseCheckStatus = false;
          this.licenseCheckError = "Licence FFTRI HDF non reconnue:\nVeuillez vérifier votre N° de licence et date de naissance.";
          //alert("Wrong birthDate / License number");
        }
      });
    }

    //Formatage N° license
    this.formRegistration.patchValue({ licenseNumber: this.formRegistration.value.licenseNumber.toUpperCase().substr(0, 6) });
  }

  private addControls() {
    //this.formRegistration.controls
  }

  public GetLicenceCheckStatus() {
    return this.licenseCheckStatus;
  }

  public StabMessage() {
    return true;
  }

}
