import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SiteInterface } from '../interfaces/site.interface';


@Component({
  selector: 'app-site-detail',
  templateUrl: './site-detail.component.html',
  styleUrls: ['./site-detail.component.scss']
})
export class SiteDetailComponent implements OnInit {

  @Input() siteData: SiteInterface;
  @Output() eventSelectSite: EventEmitter<SiteInterface> = new EventEmitter();


  constructor(private router: Router) {
    this.siteData = { siteId: 0, siteLocation: "", sitePict: "", siteDep: "", siteAddress: "" };
  }

  ngOnInit(): void {
  }

  selectSite() {
    //this.router.navigateByUrl("/site/" + this.siteData.siteLocation);

    this.eventSelectSite.emit(this.siteData);
  }

}
