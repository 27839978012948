import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { API_URL } from '../app.config';
import { SlotInterface } from '../interfaces/slot.interface';

@Component({
  selector: 'app-slot-detail',
  templateUrl: './slot-detail.component.html',
  styleUrls: ['./slot-detail.component.scss']
})
export class SlotDetailComponent implements OnInit {

  public siteLocation: string | null = "";
  public slotList: SlotInterface[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private HttpService: HttpClient
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.siteLocation = paramMap.get("location");

      this.HttpService.get<SlotInterface[]>(API_URL + "slot/" + this.siteLocation).subscribe((slotList: SlotInterface[]) => {
        this.slotList = slotList;
      });
    });
  }

  public selectSlot(slotId: number) {
    console.log("SlotId clicked: " + slotId);
    this.router.navigateByUrl("/registration/" + slotId);
  }

}
