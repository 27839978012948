<div style="text-align: center; font-size: 2.5rem;">
    <div class="size2" style="margin-bottom: 1rem; color:#93C93D; text-shadow: black 0 0 0.5rem;">
        Créneau(x) ouvert(s) pour le site de:
        <br />
        {{siteLocation}}
    </div>

    <div *ngFor="let slot of slotList">
        <div (click)="selectSlot(slot.slotId)" class="slotChoose cursorPointer" *ngIf="slot.slotAthleteMax>0">
            {{slot.slotName}}
            <br />
            <i>Dispo: {{slot.slotAthleteMax}} place(s)</i>

            <!--<br /><a href="/slotAthlete/{{slot.slotId}}" style="color:#DDD;">Liste des inscrits</a>-->
        </div>

        <div class="slotChoose bg-red" *ngIf="slot.slotAthleteMax==0">
            {{slot.slotName}}
            <br />
            <i>Dispo: {{slot.slotAthleteMax}} place</i>

            <!--<br /><a href="/slotAthlete/{{slot.slotId}}" style="color:#CCC;">Liste des inscrits</a>-->
        </div>
    </div>
    <div class="slotChoose bg-red" *ngIf="slotList.length===0">
        Aucun créneau disponible pour le moment</div>
</div>

<div style="text-align: center;">
    <button routerLink="/">Retour</button>
</div>