<ul id='footerLink'>
    <li>
        <a href='https://triathlonhdf.fr/'>
            <img src='https://trihdf.prolivesport.fr/pict/ligue-HdF bleu vert.png'
                alt='Ligue Hauts-de-France de Triathlon' title="Ligue Hauts-de-France de Triathlon" />
        </a>
    </li>
    <li>
        <a href='https://www.prolivesport.fr'>
            <img src='https://www.prolivesport.fr/tpl/2013/pict/PLS_logo/1_small.png' alt='ProLiveSport.fr'
                title='ProLiveSport.fr' />
        </a>
    </li>
    <!--<li><a href='http://www.prolivesport.fr/organisateur/'>Services aux organisateurs</a></li>-->
</ul>