<div id="headerTitle">
    Gestion des créneaux
    <br />
    Ligue Hauts-de-France de Triathlon
    <br />
    By ProLiveSport.fr
</div>
<div id="headerLogo">
    <img src='http://www.prolivesport.fr/tpl/2013/pict/PLS_logo/9_smallbis.png'
        style="position: absolute; width: 25%; right: 10px;top:5px;" />
</div>